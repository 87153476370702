<section class="trial-lesson-form for-courses" #form>
  <div class="trial-lesson-form-wrapper">
    <div class="form-instruction">
      <h2 class="form-instruction-h2">{{"FORM.TRIAL_LESSON" | translate}}</h2>
      <div class="form-instruction-list">
        <li class="form-instuction-item">{{"FORM.TEACHER" | translate}}</li>
        <li class="form-instuction-item">{{"FORM.PLAN" | translate}}</li>
        <li class="form-instuction-item">{{"FORM.LESSONS" | translate}}</li>
      </div>
    </div>


    <form class="form-form" (ngSubmit)="submit()" [formGroup]="formData">
      <input formControlName="name"  name="name" type="name" class="form-input" placeholder="{{isRu() ? 'Ваше имя' : 'Your name'}}">
      <input formControlName="phone" name="phone" type="tel" class="form-input" placeholder="{{isRu() ? 'Ваш телефон +375 XX XXX XX XX' : 'Your phone number +375 XX XXX XX XX'}}">
      <input formControlName="email" name="email" type="email" class="form-input" placeholder="Email">
      <button type="submit" [disabled]="formData.invalid || debounceTimeLeft" class="form-button">
        <p class="button-text">{{"FORM.SIGN" | translate}}</p>
        <div class="div-for-hover">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
      <div class="form-agreement">{{"FORM.TRYING" | translate}} <br>
       {{"FORM.APPLYING" | translate}}<br>
        <a href="https://drive.google.com/file/d/1jWLMsHR1g-tpFYSPc2g3JqyMq9SLJVSK/view?usp=sharing"
          target="_blank">{{"FORM.PRIVACY" | translate}}</a> <br>
        {{"FORM.ACCEPT" | translate}} <a href="https://drive.google.com/file/d/1pkoCzNZYO1PeVEc67__RLPuweTsSxTdG/view?usp=sharing"
          target="_blank">{{"FORM.PUBLIC" | translate}}</a>
      </div>
    </form>
  </div>
</section>
