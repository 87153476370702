import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesComponent } from './pages/courses/courses.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { CourseResolver } from './core/resolvers/course-resolve.service';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'course/:id',
    component: CoursesComponent,
    resolve: { course: CourseResolver },
  },
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' , useHash: true}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
