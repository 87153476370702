<footer class="footer">
  <div class="footer-wrapper">
    <div class="footer-payment-contract">
      <h3 class="footer-payment-contract-h3"><a
          href="https://drive.google.com/file/d/1pkoCzNZYO1PeVEc67__RLPuweTsSxTdG/view?usp=sharing" target="_blank">{{"FOOTER.PUBLIC" | translate}}</a>
      </h3>
      <h3 class="footer-payment-contract-h3"><a
          href="https://drive.google.com/file/d/1WWYhKiJuUPDSJm4R3rz588duSfULbgzC/view?usp=sharing" target="_blank">{{"FOOTER.AGREEMENT.1" | translate}}
        {{"FOOTER.AGREEMENT.2" | translate}} <br>
        {{"FOOTER.AGREEMENT.3" | translate}}</a></h3>
        <p class="footer-ind-ent">
          Общество с ограниченной ответственностью "АйТи Образование"<br>
          УНП 491387354<br>
          246035, г.Гомель, ул.Народного ополчения, 4а, кабинет 1-1
          </p>
      <div class="footer-payment-contract-cards"></div>
    </div>
    <div class="footer-links">
      <ul class="footer-links-list">
        <li class="footer-links-item"><button (click)="scrollTo(trends)">{{"FOOTER.DIRECTION" | translate}}</button></li>
        <li class="footer-links-item"><button (click)="scrollTo(pricelist)">{{"FOOTER.COST" | translate}}</button></li>
        <li class="footer-links-item"><button (click)="scrollTo(feedback)">{{"FOOTER.REVIEWS" | translate}}</button></li>
        <li class="footer-links-item"><button (click)="scrollTo(teachersInvitation)">{{"FOOTER.JOBS" | translate}}</button></li>
        <li class="footer-links-item"><button (click)="scrollTo(questions)">{{"FOOTER.FAQ" | translate}}</button></li>
      </ul>
    </div>
    <div class="footer-soc">
      <h3 class="footer-socials-name">{{"FOOTER.SOCIALS" | translate}}</h3>
      <ul class="footer-socials-list">
        <li class="footer-social-item"><a target="_blank"
            href="https://instagram.com/iteducation.by?utm_medium=copy_link" class="footer-social-item-href">
            <img src="../../assets/img/common/13-footer-contacts-inst.png" alt="inst"></a>
        </li>
        <li class="footer-social-item"><a target="_blank" href="https://web.facebook.com/ItEducationOnlineSchool"
            class="footer-social-item-href"><img src="../../assets/img/common/13-footer-contacts-facebook.png"
              alt="facebook"></a>
        </li>
        <li class="footer-social-item"><a target="_blank" href="https://vk.com/iteducation.online"
            class="footer-social-item-href"><img src="../../assets/img/common/13-footer-contacts-vk.png" alt="vk"></a>
        </li>
        <li class="footer-social-item"><a target="_blank"
            href="https://api.whatsapp.com/send/?phone=375257688866&text=%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D1%8E%D1%82+%D0%BA%D1%83%D1%80%D1%81%D1%8B+%D0%B4%D0%BB%D1%8F+%D1%80%D0%B5%D0%B1%D0%B5%D0%BD%D0%BA%D0%B0&app_absent=0"
            class="footer-social-item-href"><img src="../../assets/img/common/1-header-contacts-whatsapp.png"
              alt="whatsapp"></a>
        </li>
        <li class="footer-social-item"><a target="_blank" href="https://t.me/it_education_online"
            class="footer-social-item-href"><img src="../../assets/img/common/1-header-contacts-telegram.png"
              alt="telegram"></a>
        </li>
        <li class="footer-social-item"><a target="_blank" href="https://viber.click/375257688866"
            class="footer-social-item-href"><img src="../../assets/img/common/1-header-contacts-viber.png"
              alt="viber"></a>
        </li>
      </ul>
    </div>
    <div class="footer-contacts">
      <a href="tel:+375(25) 768-88-66" class="footer-contacts-phonenumber">+375(25)768-88-66</a>
      <p class="footer-contacts-email">info@iteducation.by</p>
      <a href="https://iteducation.s20.online/">
        <button (click)="triggerYM()" class="footer-private-account-button">
          <p class="button-text">{{"FOOTER.PERSONAL_ACCOUNT" | translate}}</p>
          <div class="div-for-hover">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
      </a>
      <ul class="work-time-list">{{"FOOTER.HOURS.TITLE" | translate}}
        <li class="work-time-item">{{"FOOTER.HOURS.5" | translate}}</li>
        <li class="work-time-item">{{"FOOTER.HOURS.2" | translate}}</li>
      </ul>
    </div>
  </div>
</footer>
