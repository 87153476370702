<div class="our-trends-card">
 <img src="{{ course.img }}" alt="Scratch">
 <div class="our-trends-card-h4"*ngIf="course">{{ isEng() ? course.titleEn : course.titleRu }}</div>
 <div class="our-trends-card-p" *ngIf="course">{{ isEng() ? course.shortDescriptionEn : course.shortDescriptionRu }}</div>
 <a class="card-href-more" *ngIf="course" routerLink="/course/{{ course.id }}">
  <button class="card-button-more">
   <p class="button-text">{{isEng() ? 'Show more' : 'Подробнее'}}</p>
   <div class="div-for-hover">
    <div></div>
    <div></div>
    <div></div>
   </div>
  </button>
 </a>
</div>
