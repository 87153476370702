import { Currency } from './types/currency';

export const COUNTRIES: Currency = {
  // 'UA': 'UAH',
  'RU': 'RUB',
  'BY': 'BYN'
};

export const DEFAULT_CURRENCIES = ['EUR', 'USD'];

export const DEFAULT_LANGUAGES = ['Русский', 'English'];
