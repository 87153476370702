<div class="homepage" id="#main">

  <section #trial class="trial-lesson">
    <div class="trial-lesson-wrapper">
      <div class="trial-lesson-info">
        <img class="trial-lesson-info-img" src="../../../assets/img/2-trial-lesson/favicon.png" alt="owl">
        <div class="trial-lesson-info-p">{{"MAIN.FUTURE" | translate}}</div>
        <div class="trial-lesson-info-p">{{"MAIN.SCHOOL" | translate}}</div>
        <button class="trial-lesson-info-button" (click)="scrollTo(form)">
          <div class="button-text">{{"MAIN.LESSON" | translate}}</div>
          <div class="div-for-hover">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
      </div>
      <div class=" trial-lesson-img"><img src="../../../assets/img/2-trial-lesson/2-trial-lesson-content-image-png.png"
          alt="trial lesson">
      </div>
    </div>

  </section>

  <section class="what-we-offer">
    <div class="what-we-offer-wrapper">
      <div @emergence class="what-we-offer-card">
        <div class="card-image"><img src="../../../assets/img/3-what-we-offer/3-what-we-offer-1.png"
            alt="Индивидуальный подход">
        </div>
        <div class="card-description">{{"MAIN.APPROACH" | translate}}</div>
      </div>
      <div @emergence class="what-we-offer-card">
        <div class="card-image"><img src="../../../assets/img/3-what-we-offer/3-what-we-offer-2.png"
            alt="Гибкий график обучения">
        </div>
        <div class="card-description">{{"MAIN.SCHEDULE" | translate}}</div>
      </div>
      <div @emergence class="what-we-offer-card">
        <div class="card-image"><img src="../../../assets/img/3-what-we-offer/3-what-we-offer-3.png"
            alt="Нескучные занятия"></div>
        <div class="card-description">{{"MAIN.CLASSES" | translate}}</div>
      </div>
      <div @emergence class="what-we-offer-card">
        <div class="card-image"><img src="../../../assets/img/3-what-we-offer/3-what-we-offer-4.png"
            alt="Крутые преподаватели">
        </div>
        <div class="card-description">{{"MAIN.TEACHERS" | translate}}</div>
      </div>
    </div>
  </section>

  <section class="lesson-process">
    <div class="lesson-process-wrapper">
      <h2 @emergence>{{"PROCESS.TITLE" | translate}}</h2>
      <div class="lesson-process-info">
        <div class="info-text">
          <div class="info-text-p" @emergence>{{"PROCESS.INDIVIDUAL" | translate}}<br>
            {{"PROCESS.DESCRIPTION" | translate}}
          </div>
          <div class="info-text-p" @emergence>
            {{"PROCESS.DURATION" | translate}}
          </div>
        </div>
        <div class="info-image">
          <img src="../../../assets/img/4-lesson-process/4-lesson-process-image.png" alt="lesson process">
        </div>
      </div>
    </div>
  </section>

  <section #trends class="our-trends" id="trends">
    <div class="our-trends-wrapper">

      <h2 @emergence class="our-trends-title">
        {{"TRENDS.TITLE" | translate}}
      </h2>

      <div class="our-trends-card-container toggled" #ourTrendsCardContainer>
        <it-course-card *ngFor="let course of courses" [course]="course"></it-course-card>
      </div>

      <button (click)="ourTrendsToggleContainer()" class="cards-button-show-more-courses" #showMoreCoursesBtn>
        <p class="button-text">{{"TRENDS.SHOW_MORE" | translate}}</p>
        <div class="div-for-hover">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>

      <p class="our-trends-additional-info">{{"TRENDS.QUESTION" | translate}}</p>

      <p class="our-trends-additional-info">{{"TRENDS.APPLICATION.1" | translate}}&nbsp;
        <button (click)="scrollTo(form)" class="our-trends-additional-info-button">
          <p class="button-text">{{"TRENDS.APPLICATION.2" | translate}}</p>
          <div class="div-for-hover">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button> {{"TRENDS.APPLICATION.3" | translate}}
      </p>

      <p class="our-trends-additional-info">
        {{"TRENDS.APPLICATION.4" | translate}}
      </p>

    </div>
  </section>

  <section class="advantages">
    <div class="advantages-wrapper">
      <div class="advantages-info">
        <h2>{{"ADVANTAGES.TITLE" | translate}}</h2>
        <ul class="advantages-list">
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV1" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV2" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV3" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV4" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV5" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV6" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV7" | translate}}</div>
          </li>
          <li class="advantages-list-item">
            <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="checked">
            <div class="advantages-list-item-text">{{"ADVANTAGES.ADV8" | translate}}</div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section #pricelist class="season-tickets" id="price">
    <div class="season-tickets-wrapper">
      <h2 class="season-ticket-title-h2">{{ "PRICELIST.TITLE" | translate }}</h2>
      <div class="season-ticket-title-h4">{{ "PRICELIST.INFO" | translate }}
      </div>
      <div class="season-ticket-pannel content-center">
        <p class="season-ticket-pannel__language-choice">{{ "PRICELIST.CURRENT_LANG" | translate }}</p>
        <div class="season-ticket-pannel-button"
             (click)="changeLanguage()">{{ currentLanguage }}
        </div>
      </div>
      <div class="season-ticket-pannel" *ngIf="currentLanguage === 'Русский'">
        <div class="season-ticket-pannel-button" *ngFor="let price of prices" [class.active]="price === currentPrice"
             (click)="changePriceCurrency(price)">{{ price }}
        </div>
      </div>
      <div class="season-tickets-price-container">
        <div class="price-card" *ngFor="let priceValue of priceValues">
          <h3>{{translateService.currentLang === 'ru' ? priceValue.nameRu : priceValue.nameEn}}</h3>

          <div class="price-card-prices-info">
            <h3 *ngIf="currentLanguage === 'Русский'">{{
              currentPrice === 'BYN' ? priceValue.byn :
              currentPrice === 'USD' ? priceValue.usd :
              currentPrice === 'EUR' ? priceValue.eur :
              currentPrice === 'UAH' ? priceValue.uah :
              priceValue.rub
              }} {{currentPrice}}</h3>

            <h3 *ngIf="currentLanguage === 'English'">
              {{priceValue.eur}} EUR
            </h3>
          </div>

          <h4 *ngIf="currentLanguage === 'Русский'">
            {{
            currentPrice === 'BYN' ? priceValue.byn / priceValue.quantity :
            currentPrice === 'USD' ? priceValue.usd / priceValue.quantity :
            currentPrice === 'EUR' ? priceValue.eur / priceValue.quantity :
            currentPrice === 'UAH' ? priceValue.uah / priceValue.quantity :
            priceValue.rub / priceValue.quantity
            }} {{currentPrice}} {{ "PRICELIST.ONE_LESSON" | translate }}
          </h4>

          <h4 *ngIf="currentLanguage === 'English'">
            {{priceValue.eur / priceValue.quantity}} EUR {{ "PRICELIST.ONE_LESSON" | translate }}
          </h4>

          <a *ngIf="currentLanguage === 'English'" class="price-card-pay-button" target="_blank" [href]="sanitizeUrls(priceValue.eurLink)">
            <div class="price-card-pay-button"><i class="fas fa-wallet"></i>&nbsp; {{ "PRICELIST.PAY" | translate }}</div>
          </a>

          <a *ngIf="currentLanguage === 'Русский'" class="price-card-pay-button" target="_blank" [href]="sanitizeUrls(
            currentPrice === 'BYN' ? priceValue.bynLink :
            currentPrice === 'USD' ? priceValue.usdLink :
            currentPrice === 'EUR' ? priceValue.eurLink :
            currentPrice === 'UAH' ? priceValue.uahLink :
            priceValue.rubLink
          )
          ">
            <div class="price-card-pay-button"><i class="fas fa-wallet"></i>&nbsp; {{ "PRICELIST.PAY" | translate }}</div>
          </a>

        </div>

      </div>

      <a class="season-ticket-payment-methods" target="_blank"
        href="https://drive.google.com/file/d/12clX0ZEVIk7pRGgwzCrFu-HZCNyej2Tx/view?usp=sharing">
        <p class="button-text">{{"HOME.PAYMENT_OPTIONS" | translate}}</p>
        <div class="div-for-hover">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </a>
    </div>
  </section>

  <section class="bonuses">
    <div class="bonuses-wrapper">
      <h2 class="bonuses-wrapper-h2">{{"BONUSES.TITLE" | translate}}</h2>
      <br>
      <ul class="bonuses-list">
        <br>
        <li class="bonuses-list-item">
          <img src="../../../assets/img/8-bonuses/8-bonuses-list-item-gift-image.png" alt="gift">
          <div class="bonuses-list-item-h4">{{"BONUSES.SECOND" | translate}}

            <br>{{"BONUSES.DISCOUNT" | translate}}
          </div>
        </li>
        <br>
        <li class="bonuses-list-item">
          <img src="../../../assets/img/8-bonuses/8-bonuses-list-item-gift-image.png" alt="gift">
          <div class="bonuses-list-item-h4">{{"BONUSES.FRIEND" | translate}}
          </div>
          <img src="../../../assets/img/8-bonuses/exclamatory.png" alt="">
        </li>
        <br>
        <li class="bonuses-list-item">
          <img src="../../../assets/img/8-bonuses/8-bonuses-list-item-gift-image.png" alt="gift">
          <div class="bonuses-list-item-h4">{{"BONUSES.VIDEO" | translate}}</div>
        </li>
        <br>
        <li class="bonuses-list-item">
          <img src="../../../assets/img/8-bonuses/exclamatory.png" alt="exclamatory">
          <div class="bonuses-list-item-h4">{{"BONUSES.PAYMENT" | translate}}</div>
        </li>
      </ul>
      <p>{{"BONUSES.ATTENTION" | translate}}</p>
    </div>
  </section>

  <section #questions class="faq" id="faq">
    <div class="faq-wrapper">

      <h2 class="faq-h2">{{"HOME.FAQ.TITLE" | translate}}</h2>

      <ul class="faq-list">
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.first">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.FIRST" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.FIRST_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.first=!faq.first" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.second">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.SECOND" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.SECOND_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.second=!faq.second" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png"
            alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.third">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.THIRD" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.THIRD_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.third=!faq.third" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.forth">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.FORTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.FORTH_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.forth=!faq.forth" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.fifth">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.FIFTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.FIFTH_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.fifth=!faq.fifth" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.sixth">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.SIXTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.SIXTH_ANSWER" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.sixth=!faq.sixth" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.seventh">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.SEVENTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.SEVENTH_ANSWER" | translate}}
                </h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.seventh=!faq.seventh" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png"
            alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.eighth">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.EIGHTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.EIGHTH_ANSWER" | translate}}
                </h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.eighth=!faq.eighth" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png"
            alt="plus">
        </li>
        <li class="faq-list-item">
          <div class="faq-item-h4">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="faq.ninth">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="faq-item-true-h4">{{"HOME.FAQ.NINTH" | translate}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <h5>{{"HOME.FAQ.NINTH_ANSWER.1" | translate}}<br>
                  {{"HOME.FAQ.NINTH_ANSWER.2" | translate}}<br>
                  {{"HOME.FAQ.NINTH_ANSWER.3" | translate}}<br>
                  {{"HOME.FAQ.NINTH_ANSWER.4" | translate}}</h5>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <img (click)="faq.ninth=!faq.ninth" src="../../../assets/img/9-faq/9-faq-list-item-plus-image.png" alt="plus">
        </li>
      </ul>

    </div>
  </section>

  <section #feedback class="reviews" id="reviews">
    <div class="reviews-wrapper">
      <h2 class="reviews-h2">{{"HOME.REVIEW" | translate}}</h2>
      <div class="reviews-container">
        <button class="reviews-button" (click)='rewiewsSwipeleft()' #reviewsSwipeLeftBtn id="reviews-swipe-left"><i
            class="fas fa-chevron-left"></i></button>
        <div class="review-images-frame">
          <div class="reviews-images" #reviewsImagesContainer>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-1.png" alt="1"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-2.png" alt="2"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-3.png" alt="3"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-4.png" alt="4"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-5.png" alt="5"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-6.jpg" alt="6"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-7.jpg" alt="7"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-8.jpg" alt="8"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-9.jpg" alt="9"></div>
            <div class="review-image"><img src="../../../assets/img/10-reviews/10-reviews-10.jpg" alt="10"></div>
          </div>
        </div>

        <button class="reviews-button" (click)='rewiewsSwipeRight()' #reviewsSwipeRightBtn id="reviews-swipe-right"><i
            class="fas fa-chevron-right"></i></button>
      </div>

    </div>
  </section>

  <it-trial-lesson-form (putForm)="getForm($event)"></it-trial-lesson-form>

  <section #teachersInvitation class="teachers-invitation">
    <div class="teachers-invitation-wrapper">
      <h2 class="teachers-invitation-h2">{{"JOBS.INVITATION" | translate}}<br>
        {{"JOBS.IT" | translate}}</h2>

      <a target="_top" href="mailto:info@iteducation.by">
        <button class="teachers-invitation-button">
          <p class="button-text">{{"JOBS.APPLY" | translate}}</p>
          <div class="div-for-hover">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>

      </a>

      <div class="teachers-invitation-last-word">{{"JOBS.TEAM" | translate}}<br>
        {{"JOBS.JOIN" | translate}}</div>
    </div>
  </section>
</div>
