import { Injectable } from '@angular/core';
import { Links } from './types/links';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {

  private links = {} as Links;

  setLinks(value: Links): void {
    this.links = value;
  }

  get getLinks(): Links {
    return this.links;
  }

}
