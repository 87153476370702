import { Injectable } from '@angular/core';
import { Course } from './types/course';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private courses: Course[] = [
    {
      id: '1',
      titleRu: 'Scratch',
      titleEn: 'Scratch',
      shortDescriptionRu:
        'Изучение основ программирования в визуальной среде Scratch',
      shortDescriptionEn:
        'Learning the basics of programming in the visual environment Scratch',
      courseRu: 'Создание игр и аниймаций на Scratch',
      courseEn: 'Creating games and animations on Scratch',
      img: '../../../../assets/img/5-our-trends/5-1-our-trends-card-img-scratch.png',
      longDescriptionRu:
        'Scratch - визуальная среда программирования для ребят от 7 до 12 лет. При помощи блоков на Scratch можно создавать свои интерактивные игры, красочные анимации, поздравительные открытки и мультики',
      longDescriptionEn:
        'Scratch is a visual programming environment for kids from 7 to 12 years old. With the help of Scratch blocks you can create your own interactive games, colorful animations, greeting cards and cartoons',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson they will:',
      atTheLessonRu: [
        'Познакомятся с основами программирования с помощью визуального языка Scratch',
        'Узнают, что такое система координат и смогут определять положение персонажа',
        'Запрограммируют управление персонажем с помощью клавиатуры',
        'Познакомятся с циклами и условными операторами',
        'Научатся создавать клоны объектов',
        'Узнают, что такое переменная и как ее использовать',
        'Научатся использовать математические и логические операторы',
        'Узнают понятие функции и для чего их используют',
        'Будут создавать и использовать массивы и списки',
        'Нарисуют своих уникальных персонажей в векторной и растровой графике',
        'Озвучат свой мультфильм или игру',
      ],
      atTheLessonEn: [
        'Learn the basics of programming with the visual language Scratch',
        "Learn what a coordinate system is and be able to determine a character's position",
        'Program the control of a character using the keyboard',
        'Learn about loops and conditional statements',
        "Learn to create clones of objects'",
        'Learn what a variable is and how to use it',
        'Learn to use mathematical and logical operators',
        'Learn the concept of functions and what they are used for',
        'Create and use arrays and lists',
        'Draw their unique characters in vector and raster graphics',
        'Voice their own cartoon or game',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/1-scratch/couse-scratch-image-1.png',
        '../../../assets/img/course-page/1-scratch/couse-scratch-image-2.png',
        '../../../assets/img/course-page/1-scratch/couse-scratch-image-3.png',
      ],
    },
    {
      id: '2',
      titleEn: 'Kodu Game Lab',
      titleRu: 'Kodu Game Lab',
      shortDescriptionEn:
        'Developing three-dimensional games in visual environment created by Microsoft',
      shortDescriptionRu:
        'Разработка трехмерных игр в визуальном конструкторе Microsoft',
      courseEn: 'Developing three-dimensional games in visual environment (Kodu Game Lab) created by Microsoft',
      courseRu: 'Разработка трехмерных игр в визуальном конструкторе Microsoft',
      img: '../../../../assets/img/5-our-trends/5-2-our-trends-card-img-kodu-game-lab.png',
      longDescriptionEn:
        'Kodu Game Lab is a 3D game development environment designed to teach children basic programming principles. Kodu allows you to create a landscape of the world, fill it with characters, and then program your behavior and game rules in a special visual programming language.',
      longDescriptionRu:
        'Kodu Game Lab - это среда разработки трехмерных игр, предназначенная для обучения детей основным принципам программирования. Kodu позволяет создавать ландшафт мира, заполнять его персонажами, а затем программировать свое поведение и правила игры на специальном языке визуального программирования.',
      atTheLessonTitleEn: 'During the lesson they will:',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonEn: [
        'Get acquainted with the interface of the Kodu Game Lab',
        'Create their first "Shooting Fish" game',
        'Draw their colorful landscapes',
        'Add characters and interaction between them',
        "Create a trajectory of the character's movement along the tracks",
        'Add a score of the game and a level of life for the characters',
        'Create clones and spawned objects',
        'Add several pages for the character program',
        'Create several levels for their game',
        'Add a commentator and fans for the game',
        'Make a description of their game',
      ],
      atTheLessonRu: [
        'Познакомятся с интерфейсом программы Kodu Game Lab',
        'Создадут свою первую игру “Стрельба по рыбкам”',
        'Нарисуют свои красочные ландшафты',
        'Добавят персонажей и взаимодействие между ними',
        'Создадут траекторию движения персонажа по путям',
        'Добавят подсчет очков игры, уровень жизни для персонажей',
        'Создадут клоны и порождаемые объекты',
        'Добавят несколько страниц для программы персонажа.',
        'Создадут несколько уровней для своей игры',
        'Добавят комментатора и болельщиков для игры',
        'Сделают описание своей игры.',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/2-kodu-game-lab/couse-kodu-image-1.png',
        '../../../assets/img/course-page/2-kodu-game-lab/couse-kodu-image-2.png',
        '../../../assets/img/course-page/2-kodu-game-lab/couse-kodu-image-3.png',
      ],
    },
    {
      id: '3',
      titleRu: 'Компьютерная грамотность',
      titleEn: 'Computer science for children',
      shortDescriptionRu: 'Изучение офисных программ и работа с почтой',
      shortDescriptionEn: 'Studying office programs and working with email',
      img: '../../../../assets/img/5-our-trends/5-3-our-trends-card-img-computer-literacy.png',
      courseRu: 'Курс компьютерная грамотность',
      courseEn: 'Course of computer science for children',
      longDescriptionRu:
        'Этот курс подойдет тем, кто затрудняется создавать папки, работать с файлами, пользоваться почтой и облачным хранилищем, не знает горячие клавиши, а так же Microsoft Office. Благодаря знаниям, полученным на этом курсе, можно научиться грамотно работать с компьютером.',
      longDescriptionEn:
        'This course is suitable for those who have difficulty creating folders, working with files, using email and cloud storage, do not know the hotkeys, as well as Microsoft Office. Thanks to the knowledge obtained in this course, you can learn to work competently on the computer.',
      atTheLessonTitleRu: 'Чему научится ваш ребенок:',
      atTheLessonTitleEn: 'What your child will learn:',
      atTheLessonRu: [
        'Создавать свой аккаунт почты на разных ресурсах',
        'Пользоваться облачным хранилищем: работа с документами, настройка доступа',
        'Отправлять сообщения: текстовые, графические, файлы, картинки, скриншоты',
        'Пользоваться горячими клавишами',
        'Узнает устройство персонального компьютера',
        'Менять фон рабочего стола, создавать папки',
        'Работать в проводнике и Total Commander',
        'Копировать, вырезать, перемещать файлы с компьютера на флешку и наоборот',
        'Работать с архивами (создание и распаковка)',
        'Создавать презентации в PowerPoint',
        'Работать в текстовом редакторе Microsoft Word',
        'Работать с таблицами в Microsoft Excel',
        'Пользоваться стандартными программами Windows (Paint, блокнот, записки, калькулятор, ножницы)',
      ],
      atTheLessonEn: [
        'How to create your own email account on different resources',
        'How to use cloud storage: working with documents, setting up access',
        'How to send messages: text, graphics, files, pictures, screenshots',
        'How to use keyboard shortcuts',
        'The structure of a personal computer',
        'How to change the desktop background, create folders',
        'How to work in Explorer and Total Commander',
        'How to copy, cut, move files from computer to flash drive and vice versa',
        'How to work with archives (creating and unpacking)',
        'How to create presentations in PowerPoint',
        'How to work in Microsoft Word',
        'How to work with spreadsheets in Microsoft Excel',
        'How to use standard Windows programs (Paint, notepad, memos, calculator, scissors)',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/3-computer-literacy/couse-computer-literacy-image-1.png',
        '../../../assets/img/course-page/3-computer-literacy/couse-computer-literacy-image-2.png',
        '../../../assets/img/course-page/3-computer-literacy/couse-computer-literacy-image-3.png',
      ],
    },
    {
      id: '4',
      titleRu: 'Python',
      titleEn: 'Python',
      shortDescriptionRu:
        'Изучение основ программирования Python, создание игр и приложений',
      shortDescriptionEn:
        'Learning the basics of Python programming, creating games and applications',
      img: '../../../../assets/img/5-our-trends/5-4-our-trends-card-img-python.png',
      courseRu:
        'Изучение основ программирования Python, создание игр и приложений',
      courseEn:
        'Learning the basics of Python programming, creating games and applications',
      longDescriptionRu:
        'Python один из самых популярных и востребованных языков программирования. Ребятам от 10 лет, можно смело изучать Python, ведь он достаточно простой. Единственный критерий для изучения - знание английской клавиатуры. На Python можно создавать игры, сайты, искусственный интеллект, Telegram-бота и ещё много чего интересного!',
      longDescriptionEn:
        'Python is one of the most popular programming languages. Children from 10 years can safely learn Python, because it is quite simple. The only criterion for learning - knowledge of the English keyboard. In Python you can create games, websites, artificial intelligence, Telegram-bots and many other interesting things!',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Познакомятся с понятием переменная и типы данных',
        'Будут использовать математические и логические операторы',
        'Познакомятся с условными операторами if,elif,else и операторами сравнения',
        'Используют циклы for и while',
        'Познакомятся с понятием функции и для чего их используют',
        'Будут создавать и использовать списки, словари и кортежи',
        'Импортируют модули: random, time, math и многие другие',
        'Создадут графический интерфейс пользователя на Tkinter',
        'Познакомятся с понятием ООП: классы и объекты',
        'Создадут свои красочные проекты с помощью библиотеки Pygame',
      ],
      atTheLessonEn: [
        'Learn the concept of variable and data types',
        'Use mathematical and logical operators',
        'Become familiar with the conditional if,elif,else, and comparison operators',
        'Use the for and while loops',
        'Learn the concept of functions and what they are used for',
        'Create and use lists, dictionaries, and tuples',
        'Import modules: random, time, math and many others',
        'Create a graphical user interface in Tkinter',
        'Learn the concept of OOP: classes and objects',
        'Create their own colorful projects using the Pygame library',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/4-python/python-image-1.png',
        '../../../assets/img/course-page/4-python/python-image-2.png',
        '../../../assets/img/course-page/4-python/python-image-3.png',
      ],
    },
    {
      id: '5',
      titleRu: 'Roblox',
      titleEn: 'Roblox',
      shortDescriptionRu: 'Создание красочных игр на языке Lua в Roblox Studio',
      shortDescriptionEn: 'Creating colorful games in Lua in Roblox Studio',
      img: '../../../../assets/img/5-our-trends/5-5-our-trends-card-img-roblox.png',
      courseRu: 'Создание красочных игр на языке Lua в Roblox Studio',
      courseEn: 'Creating colorful games in Lua in Roblox Studio',
      longDescriptionRu:
        'Roblox Studio – это виртуальная вселенная для творчества, создания 3D игр и воплощения всех ваших фантазий. В процессе обучения на данном курсе ученики реализуют свои игры на встроенном языке Lua, а затем делятся с друзьями в среде Roblox.',
      longDescriptionEn:
        'Roblox Studio is a virtual universe for creativity, creating 3D games and making all your fantasies come true. During the course, students implement their games in the built-in Lua language and then share them with their friends in the Roblox environment.',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Познакомятся с интерфейсом программы Roblox Studio',
        'Нарисуют дизайн своего ландшафта',
        'Добавят персонажей и взаимодействие между ними',
        'Создадут свои необычные объекты',
        'Используют объекты из Toolbox',
        'Напишут свои скрипты на языке Lua',
        'Добавят звук и свет в свою игру',
        'Создадут диалоги с персонажами',
        'Добавят телепорт для своего персонажа на другую карту',
        'Создадут красивое меню для игры',
        'Добавят команды и выберут понравившуюся команду',
        'Создадут игры по-собственному сценарию',
        'Опубликуют свои игры на Roblox',
      ],
      atTheLessonEn: [
        'Get acquainted with the interface of Roblox Studio',
        'Draw the design of their landscape',
        'Add characters and interaction between them',
        'Create their own unusual objects',
        'Use objects from Toolbox',
        'Write their scripts in Lua',
        'Add sound and light to their game',
        'Create dialogues with the characters',
        'Add a teleport for your character to another map',
        'Create a beautiful menu for the game',
        'Add teams and choose their favorite team',
        'Create games according to their own script',
        'Publish their games on Roblox',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/5-roblox/roblox-image-1.png',
        '../../../assets/img/course-page/5-roblox/roblox-image-2.png',
        '../../../assets/img/course-page/5-roblox/roblox-image-3.png',
      ],
    },
    {
      id: '6',
      titleRu: 'Photoshop',
      titleEn: 'Photoshop',
      shortDescriptionRu:
        'Работа в профессиональном графическом редакторе Photoshop',
      shortDescriptionEn:
        'Work in a professional graphics editor Photoshop',
      img: '../../../../assets/img/5-our-trends/5-6-our-trends-card-img-photoshop.png',
      courseRu: 'Работа в профессиональном графическом редакторе Photoshop',
      courseEn: 'Work in a professional graphics editor Photoshop',
      longDescriptionRu:
        'С помощью графического редактора Photoshop можно не только обрабатывать фотографии, но и создавать дизайн для сайта и социальных сетей, анимацию, баннеры, визитки, логотипы, рисовать персонажей и еще много интересного.',
      longDescriptionEn:
        'With the graphics editor Photoshop you can not only process photos, but also create designs for the website and social networks, animations, banners, business cards, logos, draw characters and many other interesting things.',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Познакомятся с интерфейсом программы Photoshop',
        'Научатся ретушировать фотографии и портреты',
        'Будут корректировать цветовой тон и насыщенность',
        'Поработают со слой-маской',
        'Создадут анимацию в Photoshop',
        'Поработают с различными фильтрами',
        'Создадут свою визитку',
        'Научатся добавлять интересные эффекты',
        'Создадут необычные надписи',
        'Научатся пользоваться инструментами выделения “Лассо” и “Волшебная палочка”',
        'Нарисуют своих персонажей с помощью инструмента “Перо”',
      ],
      atTheLessonEn: [
        'Get acquainted with the interface of Photoshop',
        'Learn to retouch photos and portraits',
        'Adjust color tone and saturation',
        'Work with the layer-mask',
        'Create an animation in Photoshop',
        'Work with different filters',
        'Create their business card',
        'Learn to add interesting effects',
        'Create unusual inscriptions',
        'Learn to use the "Lasso" and "Magic Wand" selection tools',
        'Draw their characters with the Pen tool',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/6-photoshop/photoshop-image-1.png',
        '../../../assets/img/course-page/6-photoshop/photoshop-image-2.png',
        '../../../assets/img/course-page/6-photoshop/photoshop-image-3.png',
      ],
    },
    {
      id: '7',
      titleRu: 'Python в Minecraft',
      titleEn: 'Python in Minecraft',
      shortDescriptionRu: 'Изучение Python в игровой среде Minecraft',
      shortDescriptionEn: 'Learning Python in a Minecraft game environment',
      img: '../../../../assets/img/5-our-trends/5-7-our-trends-card-img-minecraft-on-python.png',
      courseRu:
        'Изучение языка программирования Python в игровой среде Minecraft',
      courseEn:
        'Learning Python in a Minecraft game environment',
      longDescriptionRu:
        'Начни программировать на курсе Python в Minecraft! Это направление, точно заинтересует вашего ребёнка изучать Python в игровой форме! На занятиях ребята изучат синтаксис языка программирования Python в игровой среде Minecraft, создают свои миры, строят здания и сооружения.',
      longDescriptionEn:
        'Start coding in the Python in Minecraft course! This course is sure to get your child interested in learning Python in a playful way! In the lessons kids will learn the syntax of the Python programming language in the Minecraft game environment, create their own worlds, build buildings and constructions.',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Изучат синтаксис языка программирования Python',
        'Запрограммируют телепорт для своего персонажа',
        'С помощью программы будут отправлять сообщения в чат',
        'Построят дом для персонажа на языке программирования Python',
        'Решат задачки с булевыми значениями “истина” и “ложь”',
        'Познакомятся с условными операторами if,elif,else и операторами сравнения',
        'Используют циклы for и while, построят свой танцпол',
        'Познакомятся с понятием функции и для чего их используют',
        'Будут создавать и использовать списки, словари и кортежи',
        'Познакомятся с понятием ООП: классы и объекты',
        'Создадут объемные фигуры в Minecraft на Python',
        'Напишут множество своих программ на Python',
      ],
      atTheLessonEn: [
        'Learn the syntax of the Python programming language',
        'Program a teleport for his character',
        'Use Python to send messages to the chat room',
        'Build a house for a character using the Python programming language',
        'Solve tasks with Boolean values "true" and "false"',
        'Become familiar with the conditional if,elif,else, and comparison operators',
        'Use loops for and while, build their dance floor',
        'Learn the concept of functions and what they are used for',
        'Will create and use lists, dictionaries, and tuples',
        'Learn about the concept of OOP: classes and objects',
        'Create volumetric shapes in Minecraft using Python',
        'Write many of their programs in Python',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/7-minecraft-on-python/minecraft-python-image-1.png',
        '../../../assets/img/course-page/7-minecraft-on-python/minecraft-python-image-2.png',
        '../../../assets/img/course-page/7-minecraft-on-python/minecraft-python-image-3.png',
      ],
    },
    {
      id: '8',
      titleRu: 'HTML + CSS',
      titleEn: 'HTML + CSS',
      shortDescriptionRu: 'Основы верстки и создание web-сайтов',
      shortDescriptionEn: 'Basics of layout and creating web sites',
      img: '../../../../assets/img/5-our-trends/5-8-our-trends-card-img-html-plus-css.png',
      courseRu: 'Основы верстки и создание web-сайтов',
      courseEn: 'Basics of layout and creating web sites',
      longDescriptionRu:
        'HTML задает разметку сайта, CSS отвечает за его внешний вид, JavaScript делает сайт “живым”. На занятиях по созданию Web-сайтов ребенок изучит основы HTML и CSS. В процессе обучения на данном курсе ученики сверстают свой многостраничный сайт, а также лендинг.',
      longDescriptionEn:
        'HTML defines the layout of the site, CSS is responsible for its appearance, and JavaScript makes the site "live". In this course, students will learn the basics of HTML and CSS. During the course, students will design their own multi-page site and a landing page.',
      atTheLessonTitleRu: 'На этом курсе ученики изучат:',
      atTheLessonTitleEn: 'In this course, students will learn:',
      atTheLessonRu: [
        'Основные теги и атрибуты HTML',
        'Как добавлять списки и таблицы',
        'Правильную структуру HTML-документа',
        'Семантическую верстку',
        'Различные свойства CSS',
        'Как добавлять навигационные ссылки, изображения',
        'Как добавлять анимацию элементам',
        'Как использовать свойства: float, display, position, flexbox',
        'Как делать адаптивную вёрстку с использованием медиа-запросов',
        'Напишут несколько своих web-страничек',
      ],
      atTheLessonEn: [
        'Basic HTML tags and attributes',
        'How to add lists and tables',
        'The correct structure of an HTML document',
        'Semantic layout',
        'Different CSS properties',
        'How to add navigation links, images',
        'How to add animation to elements',
        'How to use the properties: float, display, position, flexbox',
        'How to do adaptive layout using media queries',
        'Write a few of their own web pages',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/8-html-css/html-css-image-1.png',
        '../../../assets/img/course-page/8-html-css/html-css-image-2.png',
        '../../../assets/img/course-page/8-html-css/html-css-image-3.png',
      ],
    },
    {
      id: '9',
      titleRu: 'JavaScript',
      titleEn: 'JavaScript',
      shortDescriptionRu: 'Создание web-сайтов и игр на языке JavaScript',
      shortDescriptionEn: 'Creating websites and games in JavaScript',
      img: '../../../../assets/img/5-our-trends/5-9-our-trends-card-img-js.png',
      courseRu: 'Создание web-сайтов и игр на языке JavaScript',
      courseEn: 'Creating websites and games in JavaScript',
      longDescriptionRu:
        'JavaScript - язык программирования, который позволяет делать: всплывающие окна, анимацию, формы для заполнения, подсказки оповещения, слайдеры. То есть делать сайт "живым". В процессе обучения на данном курсе ученики создадут несколько своих уникальных проектов.',
      longDescriptionEn:
        'JavaScript is a programming language that allows you to make: pop-ups, animations, forms to fill out, notification prompts, sliders. That is to make the site "live". In the process of learning on this course, students will create several unique projects of their own.',
      atTheLessonTitleRu: 'На этом курсе ученики изучат:',
      atTheLessonTitleEn: 'In this course, students will study:',
      atTheLessonRu: [
        'Переменные и константы',
        'Типы данных',
        'Взаимодействие: alert, prompt, confirm',
        'Операции с переменными',
        'Массивы',
        'Условные конструкции',
        'Циклы',
        'Функции',
        'Объектно-ориентированное программирование',
      ],
      atTheLessonEn: [
        'Variables and constants',
        'Data types',
        'Interaction: alert, prompt, confirm',
        'Operations with variables',
        'Arrays',
        'Conditional statements',
        'Loops',
        'Functions',
        'Object-oriented programming',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/9-java-script/js-image-1.png',
        '../../../assets/img/course-page/9-java-script/js-image-2.png',
        '../../../assets/img/course-page/9-java-script/js-image-3.png',
      ],
    },
    {
      id: '10',
      titleRu: 'Видеомонтаж',
      titleEn: 'Video editing',
      shortDescriptionRu: 'Монтаж и обработка видео для будущих блогеров',
      shortDescriptionEn: 'Video editing and processing for future bloggers',
      img: '../../../../assets/img/5-our-trends/5-10-our-trends-card-img-video-editing.png',
      courseRu: 'Монтаж и обработка видео для будущих блогеров',
      courseEn: 'Video editing and processing for future bloggers',
      longDescriptionRu:
        'Если ваш ребёнок мечтает стать блогером на YouTube, без качественного видеомонтажа и обработки ему не обойтись! После завершения этого курса ваш ребёнок научится классно монтировать и обрабатывать собственное видео!',
      longDescriptionEn:
        "If your child dreams of becoming a YouTube blogger, he/she will like our course of quality video editing and processing! After completing this course, your child will know how to edit and process their own videos!",
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Познакомятся с интерфейсом программы Sony Vegas Pro или Adobe Premiere Pro.',
        'Изучат основы видеомонтажа',
        'Добавят текст на видео, простую анимацию текста',
        'Научатся  работать со звуком',
        'Делать красивые переходы',
        'Добавят стабилизацию видео',
        'Будут изменять цветокоррекцию видео',
        'Добавят крутые анимации',
        'Научатся изменять скорость видео',
        'Добавят маски, оживлять фотографию',
        'Сделают шапку на YouTube канал',
        'Научаться делать всплывающее уведомление к видео (ставьте лайк, подписывайтесь на канал)',
      ],
      atTheLessonEn: [
        'Get acquainted with the interface of the program Sony Vegas Pro or Adobe Premiere Pro.',
        'Learn the basics of video editing',
        'Add text to the video, simple text animation',
        'Learn to work with sound',
        'Make beautiful transitions',
        'Add video stabilization',
        'Color correction of video will be changed',
        'Add cool animations',
        'Learn how to change the speed of video',
        'Add masks, bring the photo to life',
        'Make a hat for the YouTube channel',
        'Learn how to make a pop-up notification to videos (like, subscribe to the channel)',
      ],
      atTheLessonVideos: [
        '../../../../assets/video/video1.mp4',
        '../../../../assets/video/video2.mp4',
        '../../../../assets/video/video3.mp4',
      ],
    },
    {
      id: '11',
      titleRu: 'Мобильные приложения',
      titleEn: 'Mobile applications',
      shortDescriptionRu: 'Разработка мобильных приложений для Андроид',
      shortDescriptionEn: 'Development of mobile applications for Android',
      img: '../../../../assets/img/5-our-trends/5-11-our-trends-card-img-video-android.png',
      courseRu: 'Разработка мобильных приложений для Андроид',
      courseEn: 'Development of mobile applications for Android',
      longDescriptionRu:
        'Android – самая распространённая операционная система в мире. Наиболее актуальной и востребованной является разработка мобильных приложений под Android. Вместе с нами вы научитесь создавать свой чат с базой данных, красивую авторизацию в приложении, работать с фрагментами, а так же создадите свою викторину!',
      longDescriptionEn:
        'Android is the most widespread operating system in the world. The development of mobile applications for Android is in demand. Together with us your child will learn how to create your own chat with a database, beautiful authorization in the application, work with fragments, as well as create your own quiz!',
      atTheLessonTitleRu: 'На занятиях ребята:',
      atTheLessonTitleEn: 'During the lesson your child will:',
      atTheLessonRu: [
        'Познакомятся с интерфейсом программы Android Studio',
        'Добавят всплывающие сообщения',
        'Научатся добавлять диалоговые окна',
        'Создадут несколько Activity и добавят переход между страницами',
        'Создадут красивый дизайн приложения',
        'Научаться работать с изображениями',
        'Создадут списки',
        'Научатся создавать фрагменты',
        'Добавят звуки в приложении',
        'Создадут переход на любую web-страничку',
        'Напишут собственную викторину',
      ],
      atTheLessonEn: [
        'Get acquainted with the interface of Android Studio',
        'Add pop-up messages',
        'Learn to add dialog boxes',
        'Create multiple Activity and add transition between pages',
        'Create a beautiful app design',
        'Learn to work with images',
        'Create lists',
        'Learn to create fragments',
        'Add sounds to the app',
        'Create a link to any web page',
        'Write their own quiz',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/11-mobile-app/mobile-image-1.png',
        '../../../assets/img/course-page/11-mobile-app/mobile-image-2.png',
        '../../../assets/img/course-page/11-mobile-app/mobile-image-3.png',
      ],
    },
    {
      id: '12',
      titleRu: 'Java',
      titleEn: 'Java',
      shortDescriptionRu:
        'Основы программирования Java. Создание игр и приложений.',
      shortDescriptionEn:
        'Fundamentals of Java programming. Creating games and applications.',
      img: '../../../../assets/img/5-our-trends/5-12-our-trends-card-img-video-java.png',
      courseRu: 'Основы программирования Java. Создание игр и приложений.',
      courseEn: 'Fundamentals of Java programming. Creating games and applications.',
      longDescriptionRu:
        'Java - один из самых востребуемых языков программирования. Если у ребенка есть познания в Си-подобных языках, разобраться в Java не составит большого труда. В процессе обучения на данном курсе ребята создадут свои игры и приложения.',
      longDescriptionEn:
        "Java is one of the most popular programming languages. If your child has knowledge in C-like languages, it won\'t be too hard to understand Java. In this course, kids will create their own games and applications.",
      atTheLessonTitleRu: 'На этом курсе ученики изучат:',
      atTheLessonTitleEn: 'During the course students will study:',
      atTheLessonRu: [
        'Переменные и типы данных',
        'Математические операции',
        'Условные и логические операторы',
        'Циклы (For, While, Do while)',
        'Одномерные и многомерные массивы',
        'Функции и методы',
        'Основы ООП',
        'Обработку исключений',
        'Коллекции',
        'Напишут несколько своих приложений',
      ],
      atTheLessonEn: [
        'Variables and data types',
        'Mathematical operations',
        'Conditional and logical operators',
        'Loops (For, While, Do while)',
        'One dimensional and multidimensional arrays',
        'Functions and methods',
        'The basics of OOP',
        'Exception handling',
        'Collections',
        'Develop a few applications of their own',
      ],
      atTheLessonImages: [
        '../../../assets/img/course-page/12-java/java-image-1.png',
        '../../../assets/img/course-page/12-java/java-image-2.png',
        '../../../assets/img/course-page/12-java/java-image-3.png',
      ],
    },
  ];

  getAllCourses(): Course[] {
    return this.courses;
  }

  getCourseById(id: string): Course {
    const course = this.courses.filter((course) => course.id === id);
    return course[0];
  }
}
