import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CourseService } from '../../core/course-service.service';
import { Course } from '../../core/types/course';
import { animate, style, transition, trigger } from '@angular/animations';
import { ScrollingService } from 'src/app/core/scrolling.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GeolocationService } from '../../core/geolocation.service';
import { Subscription } from 'rxjs';
import {
  COUNTRIES,
  DEFAULT_CURRENCIES,
  DEFAULT_LANGUAGES,
} from '../../core/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'it-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss', './homepage-responsive.scss'],
  animations: [
    trigger('emergence', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(+120%)',
        }),
        animate(
          '1.2s',
          style({
            opacity: 1,
            transform: 'translateY(0px)',
          })
        ),
      ]),
    ]),
  ],
})
export class HomepageComponent
  implements AfterViewChecked, AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('trial', { static: false }) trial = {} as ElementRef;
  @ViewChild('questions', { static: false }) questions = {} as ElementRef;
  @ViewChild('pricelist', { static: false }) pricelist = {} as ElementRef;
  @ViewChild('trends', { static: false }) trends = {} as ElementRef;
  @ViewChild('teachersInvitation', { static: false }) teachersInvitation =
    {} as ElementRef;
  @ViewChild('feedback', { static: false }) feedback = {} as ElementRef;
  @ViewChild('ourTrendsCardContainer', { static: false })
  ourTrendsCardContainer = {} as ElementRef;
  @ViewChild('showMoreCoursesBtn', { static: false }) showMoreCoursesBtn =
    {} as ElementRef;
  @ViewChild('reviewsSwipeLeftBtn', { static: false }) reviewsSwipeLeftBtn =
    {} as ElementRef;
  @ViewChild('reviewsSwipeRightBtn', { static: false }) reviewsSwipeRightBtn =
    {} as ElementRef;
  @ViewChild('reviewsImagesContainer', { static: false })
  reviewsImagesContainer = {} as ElementRef;

  private LocationSubscription: Subscription | undefined;
  public form = {} as HTMLElement;
  public courses: Course[];
  public prices: string[] = [];
  public currentLanguage: string | undefined;
  public currentPrice = DEFAULT_CURRENCIES[0];
  private priceValuesEn = [
    {
      nameEn: '4 classes',
      nameRu: '4 занятия',
      quantity: 4,
      eur: 88,
      eurLink: 'https://checkout.bepaid.by/v2/confirm_order/prd_12a34ddc1abe87fd/27901',
    },
    {
      nameEn: '8 classes',
      nameRu: '8 занятий',
      quantity: 8,
      eur: 160,
      eurLink: 'https://checkout.bepaid.by/v2/confirm_order/prd_1f1f61f767d9c308/27901',
    },
    {
      nameEn: '16 classes',
      nameRu: '16 занятий',
      quantity: 16,
      eur: 296,
      eurLink: 'https://checkout.bepaid.by/v2/confirm_order/prd_93b0f69164b6cb2b/27901',
    },
    {
      nameEn: '32 classes',
      nameRu: '32 занятия',
      quantity: 32,
      eur: 544,
      eurLink: 'https://checkout.bepaid.by/v2/confirm_order/prd_8ede72846fb11054/27901',
    },
  ];
  private priceValuesRu = [
    {
      nameEn: '4 classes',
      nameRu: '4 занятия',
      quantity: 4,
      eur: 60,
      eurLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_4d2ee279c568282c/27901',
      usd: 60,
      usdLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_1c77ffc6946c8702/27901',
      byn: 160,
      bynLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_c15a2ed96c6761a5/27901',
      // uah: 1300,
      // uahLink:
      //   'https://w-p.by/2QPx',
      rub: 5200,
      rubLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_b32866dcc2e6a150/27901',
    },
    {
      nameEn: '8 classes',
      nameRu: '8 занятий',
      quantity: 8,
      eur: 108,
      eurLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_cb8340a7eb02c66d/27901',
      usd: 108,
      usdLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_525892287b1b0815/27901',
      byn: 304,
       bynLink:
         'https://checkout.bepaid.by/v2/confirm_order/prd_d8bd949d54f28bc3/27901',
      // uah: 2400,
      //uahLink:
     //   'https://w-p.by/2QPA',
      rub: 9600,
      rubLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_40f6912269f83b26/27901',
    },
    {
      nameEn: '16 classes',
      nameRu: '16 занятий',
      quantity: 16,
      eur: 200,
      eurLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_6d3ad0d8925c6de1/27901',
      usd: 200,
      usdLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_ab10e0a5e3313248/27901',
      byn: 576,
       bynLink:
         'https://checkout.bepaid.by/v2/confirm_order/prd_fe4a085c1f0b1725/27901',
      // uah: 4480,
      //uahLink:
      //  'https://w-p.by/2QPK',
      rub: 17600,
      rubLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_4d8c8562121511ae/27901',
    },
    {
      nameEn: '32 classes',
      nameRu: '32 занятия',
      quantity: 32,
      eur: 352,
      eurLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_9814f08bfc108a70/27901',

      usd: 352,
      usdLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_e19c52bd195619e4/27901',
      byn: 1056,
       bynLink:
         'https://checkout.bepaid.by/v2/confirm_order/prd_6f69922f8e6253a7/27901',
      // uah: 8480,
     // uahLink:
     //   'https://w-p.by/2QPQ',
      rub: 32000,
      rubLink:
        'https://checkout.bepaid.by/v2/confirm_order/prd_2c649fdc802a0cf7/27901',
    },
  ];
  public priceValues: any;
  public counter = 0;
  public faq = {
    first: false,
    second: false,
    third: false,
    forth: false,
    fifth: false,
    sixth: false,
    seventh: false,
    eighth: false,
    ninth: false,
  };

  constructor(
    private courseService: CourseService,
    private scrollService: ScrollingService,
    private sanitizer: DomSanitizer,
    private location: GeolocationService,
    public translateService: TranslateService
  ) {
    this.courses = this.courseService.getAllCourses();
  }

  ngOnInit(): void {
    this.priceValues = this.priceValuesRu;
    this.currentLanguage = this.translateService.currentLang === 'ru' ? DEFAULT_LANGUAGES[0] : DEFAULT_LANGUAGES[1];
    this.LocationSubscription = this.location
      .getCurrentLocation()
      .subscribe((country) => {
        if (COUNTRIES[country]) {
          this.prices = [...DEFAULT_CURRENCIES];
          this.prices.push(COUNTRIES[country]);
        }

        if (!COUNTRIES[country]) {
          this.prices = DEFAULT_CURRENCIES;
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.counter === 0) {
      this.reviewsSwipeLeftBtn.nativeElement.style.opacity = '.5';
    }
  }

  ngAfterViewChecked(): void {
    this.scrollService.setLinks({
      questions: this.questions,
      pricelist: this.pricelist,
      trends: this.trends,
      teachersInvitation: this.teachersInvitation,
      feedback: this.feedback,
    });
  }

  ngOnDestroy(): void {
    this.LocationSubscription ? this.LocationSubscription.unsubscribe() : null;
  }

  public sanitizeUrls(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public changePriceCurrency(price: string): void {
    this.currentPrice = price;
  }

  public scrollTo(link: HTMLElement): void {
    link.scrollIntoView({
      block: 'start',
      inline: 'center',
      behavior: 'smooth',
    });
  }

  public getForm(form: ElementRef): void {
    this.form = form.nativeElement;
  }

  public ourTrendsToggleContainer(): void {
    const condition = this.ourTrendsCardContainer && this.showMoreCoursesBtn;
    if (condition) {
      this.ourTrendsCardContainer.nativeElement.classList.toggle('toggled');
      this.showMoreCoursesBtn.nativeElement.style.display = 'none';
    }
  }

  public changeLanguage(): void {
    const firstLang = DEFAULT_LANGUAGES[0];
    const secondLang = DEFAULT_LANGUAGES[1];

    this.currentLanguage = this.currentLanguage === firstLang ? secondLang : firstLang;

    const isFirstLang = this.currentLanguage === firstLang;
    const isSecondLang = this.currentLanguage === secondLang;

    if (isFirstLang) {
      this.priceValues = this.priceValuesRu;
    }

    if (isSecondLang) {
      this.priceValues = this.priceValuesEn;
    }
  }

  public rewiewsSwipeleft(): void {
    const moreOrEqualOne = this.counter >= 1;
    if (moreOrEqualOne) {
      this.counter--;
      this.reviewsSwipeProcess();
      this.reviewsSwipeRightBtn.nativeElement.style.opacity = '1';

      const equalZero = this.counter === 0;
      if (equalZero) {
        this.reviewsSwipeLeftBtn.nativeElement.style.opacity = '.5';
      }
    }
  }

  public rewiewsSwipeRight(): void {
    const childrenAmount = this.reviewsImagesContainer.nativeElement.children.length
    const lessOrEqualOne = this.counter <= childrenAmount - 4;
    if (lessOrEqualOne) {
      this.counter++;
      this.reviewsSwipeProcess();
      this.reviewsSwipeLeftBtn.nativeElement.style.opacity = '1';
      const childWidth = this.ourTrendsGetOneElemWidth()[1] - 3;
      const condition = this.counter === childWidth;

      if (condition) {
        this.reviewsSwipeRightBtn.nativeElement.style.opacity = '.5';
      }
    }
  }

  private ourTrendsGetOneElemWidth(): number[] {
    const parent = this.reviewsImagesContainer.nativeElement;
    const childrenAmount = parent.children.length;
    const mainWidth = parent.getBoundingClientRect().width;
    const parentWidth = mainWidth / 16;
    const childWidth = parentWidth / childrenAmount;
    return [childWidth, childrenAmount];
  }

  private reviewsSwipeProcess(): void {

    const value = -this.ourTrendsGetOneElemWidth()[0] * this.counter;
    this.reviewsImagesContainer.nativeElement.style.transform = `translate(${value}rem)`;
  }
}
