<div class="body-wrapper" id="body">

 <it-header></it-header>

 <router-outlet></router-outlet>

 <it-footer></it-footer>

  <notifier-container></notifier-container>

</div>
