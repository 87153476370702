import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { NotifierService } from 'angular-notifier';
import { MailService } from '../core/mail.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'it-trial-lesson-form',
  templateUrl: './trial-lesson-form.component.html',
  styleUrls: [
    './trial-lesson-form.component.scss',
    './trial-lesson-responsive.scss',
  ],
})
export class TrialLessonFormComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form = {} as ElementRef;
  @Output() putForm = new EventEmitter<ElementRef>();
  public formData = {} as FormGroup;
  public currentUser = {} as FormData;
  public debounceTimeLeft = false;

  constructor(
    private mailService: MailService,
    private ym: NgxMetrikaService,
    private notify: NotifierService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formData = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl(null, [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(15),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  public isRu(): boolean {
    return this.translateService.currentLang === 'ru';
  }

  public submit(): void {
    this.triggerYM();

    if (JSON.parse(localStorage.getItem('wasHere') as string) === 3) {
      this.notify.notify('warning', 'Вы уже отправили максимальное количество заявок.');
      this.formData && this.formData.value ? this.formData.reset(): null;
      return;
    }

    if (this.formData && this.formData.value) {
      const letter = new FormData();
      letter.append('name', this.formData.value.name);
      letter.append('phone', this.formData.value.phone);
      letter.append('email', this.formData.value.email);
      this.mailService.sendEmail(letter).subscribe({
        next: () => {
          this.currentUser = letter
          this.debounceTimeLeft = false;
        },
        error: () => {
          this.currentUser = letter
          this.debounceTimeLeft = false;
        },
      });
      this.formData.reset();
      let numberOfApplications = JSON.parse(localStorage.getItem('wasHere') as string);

      if (!numberOfApplications) {
        localStorage.setItem('wasHere', JSON.stringify(1));
      }

      if (numberOfApplications) {
        localStorage.setItem('wasHere', JSON.stringify(numberOfApplications += 1));
      }

      this.notify.notify(
        'success',
        'Спасибо за Вашу заявку! В ближайшее время мы свяжемся с Вами.',
        'success'
      );
      this.debounceTimeLeft = true;
    }
  }

  ngAfterViewInit(): void {
    this.putForm.emit(this.form);
  }

  public triggerYM(): void {
    this.ym.reachGoal.next({ target: 'forma' });
  }
}
