<header class="header">
  <div class="header-wrapper">
    <div class="header-burger-menu">
      <div (click)='toggleBurger()' class="header-burger-menu-image" #burgerButton><i class="fas fa-bars"></i></div>
      <ul class="burger-nav hidden" #burgerList>
        <li class="burger-nav-item"><button (click)="goHome()">{{"HEADER.MAIN" | translate}}</button></li>
        <li class="burger-nav-item"><button (click)="scrollTo(trends)">{{"HEADER.DIRECTION" | translate}}</button></li>
        <li class="burger-nav-item"><button (click)="scrollTo(pricelist)">{{"HEADER.COST" | translate}}</button></li>
        <li class="burger-nav-item"><button (click)="scrollTo(questions)">{{"HEADER.FAQ" | translate}}</button></li>
        <li class="burger-nav-item"><button (click)="scrollTo(feedback)">{{"HEADER.REVIEWS" | translate}}</button></li>
      </ul>
    </div>
    <div class="logo" (click)="goHome()">
      <img src="../../assets/img/1-header/IT-Education-logo.png" alt="logo">
    </div>
    <nav class="header-nav">
      <ul class="header-list">
        <li class="header-list-item">
          <button (click)="goHome()">{{"HEADER.MAIN" | translate}}</button>
        </li>
        <li class="header-list-item">
          <button (click)="scrollTo(trends)">{{"HEADER.DIRECTION" | translate}}</button>
        </li>
        <li class="header-list-item">
          <button (click)="scrollTo(pricelist)">{{"HEADER.COST" | translate}}</button>
        </li>
        <li class="header-list-item">
          <button (click)="scrollTo(questions)">{{"HEADER.FAQ" | translate}}</button>
        </li>
        <li class="header-list-item">
          <button (click)="scrollTo(feedback)">{{"HEADER.REVIEWS" | translate}}</button>
        </li>
      </ul>
    </nav>
    <ul class="header-contacts-list">
      <li class="header-contacts-list-item"><a target="_blank"
          href="https://api.whatsapp.com/send/?phone=375257688866&text=%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D1%8E%D1%82+%D0%BA%D1%83%D1%80%D1%81%D1%8B+%D0%B4%D0%BB%D1%8F+%D1%80%D0%B5%D0%B1%D0%B5%D0%BD%D0%BA%D0%B0&app_absent=0">
          <img src="../../assets/img/common/1-header-contacts-whatsapp.png" alt="whatsapp"></a></li>
      <li class="header-contacts-list-item"><a target="_blank" href="https://t.me/it_education_online"><img
            src="../../assets/img/common/1-header-contacts-telegram.png" alt="telegram"></a></li>
      <li class="header-contacts-list-item"><a target="_blank" href="https://viber.click/375257688866"><img
            src="../../assets/img/common/1-header-contacts-viber.png" alt="viber"></a>
      </li>
    </ul>
    <mat-button-toggle-group [multiple]="false" (change)="changeLang($event)" name="languages">
      <mat-button-toggle class="mod-padding-none" value="en" [disableRipple]="true" [checked]="defaultLang === 'en'">En</mat-button-toggle>
      <mat-button-toggle class="mod-padding-none" value="ru" [disableRipple]="true" [checked]="defaultLang === 'ru'">Ru</mat-button-toggle>
    </mat-button-toggle-group>

    <a href="https://iteducation.s20.online/"><button (click)="triggerYM()" class="header-personal-account">
        <div class="button-text">{{"HEADER.PERSONAL_ACCOUNT" | translate}}</div>
        <div class="div-for-hover">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button></a>
  </div>
</header>
