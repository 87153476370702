import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Course } from '../../core/types/course';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'it-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss', './courses-responsive.scss'],
})
export class CoursesComponent implements AfterViewInit {
  public course: Course = {} as Course;
  public counter = 0;
  public vidosCounter = 1;
  public source = `../../../../assets/video/video${this.vidosCounter}.mp4`;

  @ViewChild('coursesContainer', { static: false }) coursesContainer =
    {} as ElementRef;
  @ViewChild('img1', { static: false }) img1 = {} as ElementRef;
  @ViewChild('img2', { static: false }) img2 = {} as ElementRef;
  @ViewChild('img3', { static: false }) img3 = {} as ElementRef;
  @ViewChild('coursesShowPreviousVideo', { static: false })
  coursesShowPreviousVideo = {} as ElementRef;
  @ViewChild('coursesShowNextVideo', { static: false }) coursesShowNextVideo =
    {} as ElementRef;
  @ViewChild('vidosiki', { static: false }) vidosiki = {} as ElementRef;
  @ViewChild('video', { static: false }) video = {} as ElementRef;

  constructor(private route: ActivatedRoute, private translateService: TranslateService) {
    const courseData = this.route.snapshot.data.course;
    if (courseData) {
      this.course = courseData;
    }
  }

  ngAfterViewInit(): void {
    if (this.coursesContainer) {
      setInterval(() => {
        this.slider();
      }, 2500);
    }
  }

  private slider(): void {
    this.parent().insertBefore(this.items()[0], this.items()[3]);
  }

  private parent(): HTMLElement {
    return this.coursesContainer.nativeElement.children[0].parentNode;
  }

  private items(): HTMLElement[] {
    const one = this.coursesContainer.nativeElement.children[0];
    const two = this.coursesContainer.nativeElement.children[1];
    const three = this.coursesContainer.nativeElement.children[2];
    const four = this.coursesContainer.nativeElement.children[3];
    return [one, two, three, four];
  }

  public isEng(): boolean {
    const currentLang = this.translateService.currentLang;

    return currentLang === 'en';
  }

  public getCurrentLang(): string {
    return this.translateService.currentLang;
  }
}
