import { Component, OnInit } from '@angular/core';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'it-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'it-education';
  constructor(private ym: NgxMetrikaService,
              private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.use(environment.defaultLocale)
  }
}
