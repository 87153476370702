import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class MailService {
    private baseUrl = "https://it-education-mailer.herokuapp.com/";

    // private baseUrl = "http://localhost:8080/";

    constructor(private http: HttpClient) {}

    sendEmail(letter: FormData): Observable<any> {
        return this.http.post(this.baseUrl, letter);
    }
}