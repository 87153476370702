import { Component, Input, OnInit } from '@angular/core';
import { Course } from '../../../core/types/course';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'it-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss', './course-card-responsive.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() course!: Course;

  private currentLanguage!: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;
  }

  public isEng(): boolean {
    this.currentLanguage = this.translateService.currentLang;

    return this.currentLanguage === 'en';
  }
}
