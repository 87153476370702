<section class="course">
  <div class="course-overview">
    <div class="course-overview-wrapper">
      <div class="course-overview-h2">{{ isEng() ? course.courseEn : course.courseRu }}</div>
      <div class="course-overview-p" lang="ru">{{ isEng() ? course.longDescriptionEn : course.longDescriptionRu }}</div>
      <div class="content">
        <div class="content-left">
          <div class="content-left-info">
            <div class="list-name">{{ isEng() ? course.atTheLessonTitleEn : course.atTheLessonTitleRu }}</div>
            <ul class="content-left-list">
              <ng-container *ngIf="getCurrentLang() === 'en'">
                <li *ngFor="let advantage of course.atTheLessonEn" class="content-left-item">
                  <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="pointer">
                  <div class="content-left-list-text">{{advantage}}</div>
                </li>
              </ng-container>

              <ng-container *ngIf="getCurrentLang() === 'ru'">
                <li *ngFor="let advantage of course.atTheLessonRu" class="content-left-item">
                  <img src="../../../assets/img/6-advantages/6-advantages-list-item-image.png" alt="pointer">
                  <div class="content-left-list-text">{{advantage}}</div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="content-right-images" *ngIf="course.atTheLessonImages; else videoContainer">
          <div class="content-images" #coursesContainer>
            <div #img1 class="content-image"><img
                src="{{!!course.atTheLessonImages[0] ? course.atTheLessonImages[0] : 'https://i.ibb.co/LQVbzcR/couse-scratch-image-1.png'}}"
                alt="scratch">
            </div>
            <div #img2 class="content-image"><img
                src="{{!!course.atTheLessonImages[1] ? course.atTheLessonImages[1] : 'https://i.ibb.co/LQVbzcR/couse-scratch-image-1.png'}}"
                alt="scratch">
            </div>
            <div #img3 class="content-image"><img
                src="{{!!course.atTheLessonImages[2] ? course.atTheLessonImages[2] : 'https://i.ibb.co/LQVbzcR/couse-scratch-image-1.png'}}"
                alt="scratch">
            </div>
            <div #img4 class="content-image"></div>
          </div>
        </div>
        <ng-template #videoContainer>
          <div class="content-videos" #vidosiki>
            <div class="content-video">
              <video controls="controls">
                <source #video type='video/mp4' [src]="source">
              </video>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <it-trial-lesson-form></it-trial-lesson-form>

</section>
