import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { ScrollingService } from '../core/scrolling.service';
import { Links } from '../core/types/links';

@Component({
  selector: 'it-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './footer-responsive.scss'],
})
export class FooterComponent {
  public elements = {} as Links;
  public questions = {} as HTMLElement;
  public feedback = {} as HTMLElement;
  public trends = {} as HTMLElement;
  public teachersInvitation = {} as HTMLElement;
  public pricelist = {} as HTMLElement;
  private baseUrl = 'https://iteducation.s20.online/';

  constructor(
    private router: Router,
    private scrollingService: ScrollingService,
    private ym: NgxMetrikaService
  ) {}

  ngAfterViewChecked(): void {
    this.elements = this.scrollingService.getLinks;
    if (this.elements.questions) {
      this.questions = this.elements.questions.nativeElement;
    }
    if (this.elements.feedback) {
      this.feedback = this.elements.feedback.nativeElement;
    }
    if (this.elements.pricelist) {
      this.pricelist = this.elements.pricelist.nativeElement;
    }
    if (this.elements.teachersInvitation) {
      this.teachersInvitation = this.elements.teachersInvitation.nativeElement;
    }
    if (this.elements.trends) {
      this.trends = this.elements.trends.nativeElement;
    }
  }

  public scrollTo(link: HTMLElement): void {
    this.goHome();
    link.scrollIntoView({
      block: 'start',
      inline: 'center',
      behavior: 'smooth',
    });
  }

  public goHome(): void {
    this.router.navigate(['']);
  }

  public triggerYM(): void {
    this.ym.reachGoal.next({ target: this.baseUrl });
  }
}
