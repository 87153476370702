import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Course } from "../types/course";
import { CourseService } from "../course-service.service";

@Injectable({providedIn: "root"})

export class CourseResolver implements Resolve<Course> {
  constructor(private course: CourseService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Course {
    return this.course.getCourseById(route.params.id);
  }
}
