import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserLocation } from './types/userlocation';

@Injectable({
  providedIn: "root"
})

export class GeolocationService {
  constructor(private http: HttpClient) {}

  public getCurrentLocation(): Observable<string> {
    return this.http.get<UserLocation>('https://ipinfo.io?token=bf75099954236d')
      .pipe(
        map(response => response.country)
      )
  }
}


