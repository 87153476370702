import {
  AfterViewChecked,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { Links } from '../core/types/links';
import { ScrollingService } from '../core/scrolling.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'it-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header-responsive.scss'],
})
export class HeaderComponent implements AfterViewChecked {
  @ViewChild('burgerButton', { static: false }) burgerButton = {} as ElementRef;
  @ViewChild('burgerList', { static: false }) burgerList = {} as ElementRef;
  public defaultLang = environment.defaultLocale;
  public elements = {} as Links;
  public questions = {} as HTMLElement;
  public feedback = {} as HTMLElement;
  public trends = {} as HTMLElement;
  public pricelist = {} as HTMLElement;
  private baseUrl = 'https://iteducation.s20.online/';

  constructor(
    private router: Router,
    private scrollingService: ScrollingService,
    private translateService: TranslateService,
    private ym: NgxMetrikaService
  ) {}

  ngAfterViewChecked(): void {
    this.elements = this.scrollingService.getLinks;
    if (this.elements.questions) {
      this.questions = this.elements.questions.nativeElement;
    }
    if (this.elements.feedback) {
      this.feedback = this.elements.feedback.nativeElement;
    }
    if (this.elements.pricelist) {
      this.pricelist = this.elements.pricelist.nativeElement;
    }
    if (this.elements.trends) {
      this.trends = this.elements.trends.nativeElement;
    }
  }

  public scrollTo(link: HTMLElement): void {
    this.goHome();
    link.scrollIntoView({
      block: 'start',
      inline: 'center',
      behavior: 'smooth',
    });
  }

  public goHome(): void {
    this.router.navigate(['']);
    this.toggleBurger();
  }

  public toggleBurger(): void {
    this.burgerList.nativeElement.classList.toggle('hidden');
  }

  public triggerYM(): void {
    this.ym.reachGoal.next({ target: this.baseUrl });
  }

  public changeLang(e: MatButtonToggleChange): void {
    this.translateService.use(e.value);
  }
}
