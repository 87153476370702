import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { TrialLessonFormComponent } from './trial-lesson-form/trial-lesson-form.component';
import { CourseCardComponent } from './pages/homepage/course-card/course-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMetrikaModule } from '@kolkov/ngx-metrika';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { TranslateModule } from '@ngx-translate/core';
import { I18n } from './app.i18n.config';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    CoursesComponent,
    TrialLessonFormComponent,
    CourseCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    ScrollingModule,
    FormsModule,
    MatButtonToggleModule,
    TranslateModule.forRoot(I18n.config),
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    HttpClientModule,
    NgxMetrikaModule.forRoot({
      id: 82881442,
      defer: true,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true
    },)
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
